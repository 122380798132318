import ReactDOM from 'react-dom';
import React, {Component, useState, useRef} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import HTTPrequest from '../../helpers/HTTPrequest';


function EmailDialogSimple(props) {
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(true);
  const [ resolver, setResolver ] = useState({ resolver: null })
  
  const emailref = useRef();
  

  
  const isEmail = (val) => {
    if(val === '')
        return true
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let res = re.test(String(val).toLowerCase());  
    // console.log(res)
    return res
  }
  
  const handleChange = (e) => {
      setEmail(e.target.value)
      setValid(isEmail(e.target.value))
  }
  
  const annotateVideo = () => {
    let ind = props.videoData.file_name.indexOf(".");
    let result = props.videoData.file_name.substring(0, ind) +".json"
    return HTTPrequest('annotate', 'POST', {
          video: props.videoData.id,
          file_name: result,
        }) 
    }
    
  const postEmail = (annotation_id) => {
    return HTTPrequest('notify', 'POST', {
          annotation: annotation_id,
          email: email,
        }) 
    }
  
  const handleAnnotate = () => {
    
    annotateVideo().then(response => {
      props.setDisabledRouteButton(true)
      props.setDisabledAnnotate(true)
      props.setAnnotateLoading(true)
      props.setTicking(true)
      props.setOpenEmailDialog(false)
      if(email!=''){
        postEmail(response.data.id).then(res=>{
          // nothing to do
        }).catch(function (err) {
          console.log("postEmail error: ", err)
        })
      }
    }).catch(function (err) {
      console.log("annotateVideo error: ", err)
    });  
    
    
    
    
    
  }
  
  return (
  
      <div>
      <Dialog
        open={props.openEmailDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Notify via email</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Annotation process may take a long time depending on the size of the video. 
        You can provide an email below and we will notify you via that email when the annotation is done.
        Or you can leave the email field blank and check the dashboard later.
        </DialogContentText>
        <TextField
            helperText="Notify me when the annotation is finished"
            label="Email"
            onChange={(e) => handleChange(e)}
            error={!valid}
            ref={emailref}
        />
        
          
        </DialogContent>
        <DialogActions>
          <Button 
            disabled={!valid}
            onClick={()=>{
            handleAnnotate()
            }}
            color="primary" 
            variant="contained"
            >
            Start Annotating
          </Button>
        </DialogActions>
      </Dialog>
    </div>  
      
  );
  
}

export default EmailDialogSimple;