// import ReactDOM from 'react-dom';
import React, { useState, useEffect  } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { makeStyles  } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ProjectCard from './components/cards/ProjectCard';
import ProjectDialog from './components/dialogs/ProjectDialog';
import HTTPrequest from './helpers/HTTPrequest';
import Pagination from '@mui/material/Pagination';
import ReportGeneratorDialog from './components/dialogs/ReportGeneratorDialog';
import BulkProjectsDialog from './components/dialogs/BulkProjectsDialog';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';




function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://roadsmonitoring.com/">
        Roads Monitoring
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
   projects: {
     marginTop:5,
     marginLeft:5
     
   },
   appBar: {
    position: 'relative',
  },
  button:{
    color: "white!important",
  },
}));




const getProjects = (page) => {
  var param = ''
  if(page > 1)
    param=`?page=${page}`
  return HTTPrequest('project', 'GET', {}, param)
  
};







export default function MainPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [projectDialogOpen, setProjectDialogOpen] = React.useState(false);
  const [projects, setProjects] = useState({ init: [] });
  const [projectData, setProjectData] = useState({
    name:'',
    created_at:'',
    url:null,
    id: null
  });
  const [projectsCount, setProjectsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [reportGeneratorOpen, setReportGeneratorOpen] = useState(false);
  const [bulkProjectsOpen, setBulkProjectsOpen] = useState(false);

  const handlePageChange = (p) =>{
    setCurrentPage(p)
  }
  
  
  const handleUpdate = () => {
    getProjects(currentPage).then(t => {
      setProjects(t.results)
      setProjectsCount(t.count)
    }).catch(function (err) {
      console.log("getProjects error")
      if (err.status === 401) navigate('/login');
    });
  }
  
  useEffect(() => {
        if(!projectDialogOpen)
          handleUpdate()
    }, [
      projectDialogOpen, currentPage
    ]);

  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
    <CssBaseline />
    <AppBar position="static" className={classes.appBar}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
      <Button size="small" className={classes.button} onClick={() => {
          localStorage.removeItem('token');
          navigate('/login');
        }}>Logout</Button>
      </Grid>
    </AppBar>
    
    <div className={classes.projects}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
      >
        <Grid item xs="auto">
          <Button color="secondary" aria-label="add project" variant="outlined" onClick={() => {
            setProjectData({
              name:'',
              created_at:'',
              url:null,
              id: null
            });
            setProjectDialogOpen(true);
          }}>
            <AddIcon fontSize="large"/> Add Project
          </Button>
        </Grid>
        
        <Grid item xs="auto">
          <Button color="secondary" variant="outlined" onClick={() => {
            setReportGeneratorOpen(true)
            
          }}>
            <AssessmentIcon fontSize="large"/>Report Generator
          </Button>
        </Grid>
        {
        // <Grid item xs="auto">
        //   <Button color="secondary" variant="outlined" onClick={() => {
        //     setBulkProjectsOpen(true)
            
        //   }}>
        //     <LibraryAddIcon fontSize="large"/>Add Projects in Bulk
        //   </Button>
        // </Grid>
        }
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {
        
        (Object.values(projects)).map(t => (
          <Grid key={`Project-${t.id}`}>
            <ProjectCard
                created_at={t.created_at}
                name={t.name}
                video_url={t.videos!=undefined?(t.videos.length>0?t.videos[0].url:null):null}
                project_id={t.id}
                setProjectDialogOpen={setProjectDialogOpen}
                setProjectData={setProjectData}
                handleUpdate={handleUpdate}
            />
          </Grid>
        ))}
      </Grid>
      
      <Pagination 
        count={Math.max(0,Math.floor((projectsCount-1)/10))+1} 
        onChange={(e,p) => handlePageChange(p)}
        
        variant="outlined" 
        color="secondary" 
        />
      </Grid>
      
      
      <ProjectDialog 
        setProjectDialogOpen={setProjectDialogOpen}
        projectDialogOpen={projectDialogOpen}
        projectData={projectData}
        setProjectData={setProjectData}
      />
      
      <ReportGeneratorDialog
        reportGeneratorOpen={reportGeneratorOpen}
        setReportGeneratorOpen={setReportGeneratorOpen}
      />
      
      <BulkProjectsDialog
        bulkProjectsOpen={bulkProjectsOpen}
        setBulkProjectsOpen={setBulkProjectsOpen}
      />
      
      
    </div>
    
    
    <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1">
            Roads Monitoring: Monitor Roads Traffic Programmatically.
          </Typography>
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}