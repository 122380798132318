import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@material-ui/core/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import HTTPrequest from '../../helpers/HTTPrequest';


let default_interval = "15"

const ExcelReportsDialog = (props) => {
  
  const [dateTime, setDateTime] = useState(dayjs().minute(0));
  // const [reportDisabled, setReportDisabled] = useState(true);
  const [intervals, setIntervals] = useState(["1", "5","10","15","20","30","60"]);
  const [interval, setInterval] = useState(default_interval);
  const [loadDonwload, setLoadDonwload] = useState(false);

  const handleChange = (newValue) => {
    setDateTime(newValue);
    // setReportDisabled(false)
  };
  
  const handleIntervalChange = (e) => {
    setInterval(e.target.value)
  }
  
  
  const processData = () => {
    return new Promise((resolve, reject) => {
      HTTPrequest('process', 'POST', {
        area_group: props.area_group
      }).then(p => {
          resolve(p.data)
      }).catch(e => {
        console.log("error in handleProcess: ", e)
        reject(null)
      })
    })
  }
  
  useEffect(() => {
      
  }, [
      props
  ]);
  
    return (
        <div>
          <Dialog
            open={props.open}
            onClose={()=>{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Setting Routes</DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Date and Time picker"
                    value={dateTime}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <br/>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Time interval"
                    defaultValue={default_interval}
                    helperText="Report's time interval (in minutes)"
                    onChange={handleIntervalChange}
                  >
                    {intervals.map((option) => (
                      <MenuItem key={option} value={option} >
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <br/>
                  <LoadingButton 
                    loading={loadDonwload}
                    // disabled={reportDisabled}
                    onClick={()=>{
                      setLoadDonwload(true)
                      processData().then(p => {
                        HTTPrequest('excelreport', 'POST', {
                          "area_group":props.area_group,
                          "start_time": dateTime.format('YYYY-MM-DD HH:mm')+':00',
                          "interval": parseInt(interval)
                          
                        }).then(v => {
                          // window.open(v.url, '_blank', 'noreferrer');
                          fetch(v.url).then(response => {
                            response.blob().then(blob => {
                                // Creating new object of PDF file
                                const fileURL = window.URL.createObjectURL(blob);
                                // Setting various property values
                                let alink = document.createElement('a');
                                alink.href = fileURL;
                                alink.download =props.projectName+'.xlsx';
                                alink.click();
                                setLoadDonwload(false)
                            })
                          })
                        })  
                      })
                      
                    }} 
                    variant="contained"
                    color="secondary" >
                    Download Report
                  </LoadingButton>
                </LocalizationProvider>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                // setReport([])
                props.setOpenReportsDialog(false)
              }} color="primary">
                Close
              </Button>
              
            </DialogActions>
          </Dialog>
        </div>
    );

}

export default ExcelReportsDialog;