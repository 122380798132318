import React, {useState, useEffect, useRef} from 'react';

import ReactDOM from 'react-dom';
import AreaDialog from './AreaDialog'
import HTTPrequest from '../../helpers/HTTPrequest';


function Canvas(props) {
    const canvasref = useRef();
    const [started, setStarted] = useState(false);
    const [close, setClose] = useState(false);
    const [previousPointX, setPreviousPointX] = useState('');
    const [previousPointY, setPreviousPointY] = useState('');
    const [startX, setStartX] = useState('');
    const [startY, setStartY] = useState('');
    const [stored_lines, set_stored_lines] = useState([]);
    const [in_progress_area, set_in_progress_area] = useState([]);
    const [stored_path, set_stored_path] = useState(props.stored_path);
    const [open, setOpen] = useState(false);
    // const [video_width, set_video_width] = useState(props.video_width);
    // const [video_height, set_video_height] = useState(props.video_height);
    const [annotationData, setAnnotationData] = useState(null);
    
    
    
    
    const loadAnnotationData = () => {
        HTTPrequest('signedurl', 'POST', {
            file_name: props.annotateFileName,
            type: 'GET',
            file_type: 'annotate',
            project: props.project_id
          }).then( r=>{
              fetch(r.data.url).then((response) => response.json()).then((responseJson) => {
                 let filtered_data = responseJson['annotation_results'][0]['object_annotations'].filter(t=>(t.confidence>0.5 && t.entity.description==='car' || t.entity.description==='truck'))
                  setAnnotationData(filtered_data)
               })
          })
    }
    
    useEffect(() => {
        set_stored_path(props.stored_path)
        componentDidMount();
        if(props.annotationIsDone && props.canvasDrawAnnotate && annotationData===null&& props.annotateFileName!=null && props.project_id!=null){
            loadAnnotationData()// not showing the annotation for now
        }
    }, [
        props.stored_path, props.canvasDrawAnnotate, props.videoID, props.videoTime, props.video_width, props.video_height
      ]);
    
    const drawLines = (_stored_lines, _stored_path) => {
        const canvas = canvasref.current;
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0,0,canvas.width,canvas.height);
        
        if(_stored_lines.length>0){
            ctx.beginPath();
            ctx.moveTo(_stored_lines[0].x1,_stored_lines[0].y1);
            ctx.lineTo(_stored_lines[0].x2,_stored_lines[0].y2);
            ctx.strokeStyle = 'white'
            ctx.stroke();
            for(var i=1;i<_stored_lines.length;i++){
                ctx.lineTo(_stored_lines[i].x2,_stored_lines[i].y2);
                ctx.strokeStyle = 'white'
                ctx.stroke();
            }
            ctx.fill();
            ctx.closePath();
        }
        
        for(var i=0;i<_stored_path.length;i++){
            ctx.beginPath();
            ctx.moveTo(_stored_path[i][0].x1,_stored_path[i][0].y1);
            ctx.lineTo(_stored_path[i][0].x2,_stored_path[i][0].y2);
            ctx.stroke();
            for(var j=1;j<_stored_path[i].length;j++){
                ctx.lineTo(_stored_path[i][j].x2,_stored_path[i][j].y2);
                ctx.strokeStyle = 'white'
                ctx.stroke();
            }
            ctx.fill();
            ctx.closePath();
        }
        
        if(props.canvasDrawAnnotate && annotationData!=null){
            var box_draws = []
            var saved_inds = []
            annotationData.map((t,i) =>{ 
                if(t.frames!=undefined){
                    t.frames.map(f=>{
                    var seconds = 0
                    var nanos = 0
                    if(f.time_offset.seconds!=undefined)
                        seconds = f.time_offset.seconds
                    if(f.time_offset.nanos!=undefined)
                        nanos = f.time_offset.nanos
                    let tstamp=parseFloat(`${seconds}.${nanos}`)
                    if(tstamp>=props.videoTime&&tstamp<props.videoTime+0.1 && !saved_inds.includes(i)){
                        saved_inds.push(i)
                        box_draws.push({
                            normalized_bounding_box: f.normalized_bounding_box,
                            description: t.entity.description
                        })
                    }
                        
            })}})
            ctx.strokeStyle = '#4285F4'
            for(var j=0;j<box_draws.length;j++){
                ctx.beginPath();
                ctx.moveTo(box_draws[j].normalized_bounding_box.left*props.video_width,box_draws[j].normalized_bounding_box.bottom*props.video_height);
                ctx.lineTo(box_draws[j].normalized_bounding_box.left*props.video_width,box_draws[j].normalized_bounding_box.top*props.video_height);
                ctx.lineTo(box_draws[j].normalized_bounding_box.right*props.video_width,box_draws[j].normalized_bounding_box.top*props.video_height);
                ctx.lineTo(box_draws[j].normalized_bounding_box.right*props.video_width,box_draws[j].normalized_bounding_box.bottom*props.video_height);
                ctx.lineTo(box_draws[j].normalized_bounding_box.left*props.video_width,box_draws[j].normalized_bounding_box.bottom*props.video_height);
                ctx.stroke();
                ctx.closePath();
            }
        }
    }

    const handleMouseDown = (event) => { 
        
    }
    const handleMouseMove = (event) => {
        
        if(started){
            if (!close){
                let lines = [...stored_lines];
                lines.push({x1:previousPointX, y1:previousPointY, x2:event.offsetX, y2:event.offsetY})
                drawLines(lines, stored_path)
            }else{
                let lines = [...stored_lines];
                lines.push({x1:previousPointX, y1:previousPointY, x2:startX, y2:startY})
                drawLines(lines, stored_path)
            }
            
            if((in_progress_area.length>1)&&(((event.offsetX - startX)**2+(event.offsetY - startY)**2) < 20)){
                setClose(true)
                
            }else{
                setClose(false)
            }
        }
    }
    
    const handleMouseUp = (event) => {
        
        if(started){
            var tmp_stored_lines = [...stored_lines]
            if (!close){
                set_in_progress_area([...in_progress_area,{x1:previousPointX, y1:previousPointY, x2:event.offsetX, y2:event.offsetY}])
                tmp_stored_lines.push({x1:previousPointX, y1:previousPointY, x2:event.offsetX, y2:event.offsetY})
                set_stored_lines(tmp_stored_lines)
            }else{
                // closing object
                tmp_stored_lines.push({x1:previousPointX, y1:previousPointY, x2:startX, y2:startY})
                set_stored_lines(tmp_stored_lines)
                setOpen(true)
            }
            drawLines(tmp_stored_lines, stored_path)
        }else{
            setStarted(true)
            setStartX(event.offsetX)
            setStartY(event.offsetY)
        }
        setPreviousPointX(event.offsetX)
        setPreviousPointY(event.offsetY)
    }
    const componentDidMount = () => {
        const canvas = canvasref;
        const ctx = canvas.current.getContext("2d");
        if (ctx.canvas.width != props.video_width)
            ctx.canvas.width = props.video_width
        if (ctx.canvas.height != props.video_height)
            ctx.canvas.height = props.video_height
        ctx.fillStyle = 'rgb(255, 255, 255, 0.5)';
        ctx.fillRect(0, 0, 640, 425);
        if(stored_path.length>0)
            drawLines([], stored_path)
    }
    
    
    
    return (
        <div>
                <canvas id="canvas" ref={canvasref} style={{position: 'absolute', zIndex: props.canvasZIndex, width: props.video_width, height: props.video_height}}
                    
                    onMouseDown={
                        e => {
                            if (props.canvasEventsEnable){
                                let nativeEvent = e.nativeEvent;
                                handleMouseDown(nativeEvent);
                            }
                        }}
                    onMouseMove={
                        e => {
                            if (props.canvasEventsEnable){
                                let nativeEvent = e.nativeEvent;
                                handleMouseMove(nativeEvent);
                            }
                        }}    
                    onMouseUp={
                        e => {
                            if (props.canvasEventsEnable){
                                let nativeEvent = e.nativeEvent;
                                handleMouseUp(nativeEvent);
                            }
                        }}
                />
                <AreaDialog
                    // canvas_state={this}
                    set_stored_path={set_stored_path}
                    stored_path={stored_path}
                    stored_lines={stored_lines}
                    setStarted={setStarted}
                    set_in_progress_area={set_in_progress_area}
                    set_stored_lines={set_stored_lines}
                    open={open}
                    setOpen={setOpen}
                    drawLines={drawLines}
                    
                    
                    stored_areas={props.stored_areas}
                    set_stored_areas={props.set_stored_areas}
                    // enter_exists_set_state={props.enter_exists_set_state}
                    // exit_exists_set_state={props.exit_exists_set_state}
                    setDisabledRouteButton={props.setDisabledRouteButton}
                    // enterExists={props.enterExists}
                    // exitExists={props.exitExists}
                    setAreaExists={props.setAreaExists}
                />
            </div>    
        
        )

}
export default Canvas;
