import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Files from "react-butterfiles";
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import HTTPrequest from '../../helpers/HTTPrequest';
import Button from '@mui/material/Button';
import Canvas from '../dialogs/Canvas';
import Alert from '@mui/material/Alert';
import { makeStyles  } from '@material-ui/core/styles';
import ExcelReportsDialog from '../dialogs/ExcelReportsDialog';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import EmailDialogSimple from '../dialogs/EmailDialogSimple';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {postProject,getPresignedPostData, uploadFileToS3, postVideo, updateVideoSource} from '../../helpers/tools';





const useStyles = makeStyles((theme) => ({
  videoContainer:
  {
     height:300,
     width:400,
     overflow: "hidden",
    position: "relative"
     
  },
  
  video:
  {
    width:"100%", 
    height:"auto",
    
  },
  main:{
  }

}));


const getVideoForProject = (project_id) => {
  return new Promise((resolve, reject) => {
    if(project_id==null || project_id==undefined){
        resolve(null)
        return
    }
    
    HTTPrequest('video', 'GET', {}, '?project='+project_id).then(v => {
      resolve(v.results)
    })
  })
  
}


const getAnnotationForVideo = (video_id) =>{
  return new Promise((resolve, reject) => {
    if(video_id==null || video_id==undefined){
        resolve(null)
        return
    }
    HTTPrequest('annotate', 'GET', {}, '?video='+video_id).then(an => {
      resolve(an.results)
    })
  })
}

const getAnnotationForProject = (project_id) => {
  
  return new Promise((resolve, reject) => {
    if(project_id==null || project_id==undefined){
        resolve(null)
        return
    }
    getVideoForProject(project_id).then(v =>{
      
      if (v.length>0)
        getAnnotationForVideo(v[0].id).then(an => {
          resolve(an)
        })
      else{ 
        resolve(null)
        return
      }
    })
    
  })
}



const ProjectDialog = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const videoref = useRef();
  
  const [projectNameDisabled, setProjectNameDisabled] = useState(false);
  const [projectName, setProjectName] = React.useState(props.projectData.name);
  
  
  const [disabledUpload, setDisabledUpload] = useState(false);
  const [videoSource, setVideoSource] = useState(props.projectData.url);
  const [disabledAnnotate, setDisabledAnnotate] = useState(true);
  const [videoLoading, setVideoLoading] = useState(false);
  const [annotateLoading, setAnnotateLoading] = useState(false);
  const [annotationFailed, setAnnotationFailed] = useState(false);
  const [canvasDisplay, setCanvasDisplay] = useState(false)
  const [canvasEventsEnable, setCanvasEventsEnable] = useState(true) //
  const [ticking, setTicking] = useState(false)
  const [disabledRouteButton, setDisabledRouteButton] = useState(true)//
  const [disabledRouteDownloadButton, setDisabledRouteDownloadButton] = useState(true)//
  const [disabledRouteLoadButton, setDisabledRouteLoadButton] = useState(true)//
  const [runDisplayAreas, setRunDisplayAreas] = useState(false)//

  
  
  
  const [videoData, setVideoData] = useState(null);
  const [storedAreas, setStoredAreas] = useState([])
  const [areaExists, setAreaExists] = useState(false);
  const [disabledVideoController, setDisabledVideoController] = useState(false)
  const [videoWidth, setVideoWidth] = useState(400)
  const [videoHeight, setVideoHeight] = useState(300)
  const [storedPath, setStoredPath] = useState([])
  
  const [areaGroupID, setAreaGroupID] = useState(null)
  const [disabledReportsButton, setDisabledReportsButton] = useState(true)//
  const [openReportsDialog, setOpenReportsDialog] = useState(false)
  const [canvasZIndex, setCanvasZIndex] = useState('999');//
  const [canvasDrawAnnotate, setCanvasDrawAnnotate] = useState(false);//
  const [annotateFileName, setAnnotateFileName] = useState(null);//
  const [videoTime, setVideoTime] = useState(0);
  const [dialogTitle, setDialogTitle] = useState('New Project');
  const [routeLoading, setRouteLoading] = useState(false);
  const [alertUploadOpen, setAlertUploadOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [annotationIsDone, setAnnotationIsDone] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [projectNameAsFileName, setProjectNameAsFileName] = useState(false);
  const [projectNameAsFileNameDisabled, setProjectNameAsFileNameDisabled] = useState(false);
  
  
  
  
  const handleInit = () => {
    setProjectNameDisabled(false)
    setProjectName(props.projectData.name);
    setDisabledUpload(false);
    setVideoSource(props.projectData.url);
    setDisabledAnnotate(true);
    setVideoLoading(false);
    setAnnotateLoading(false);
    setAnnotationFailed(false);
    setCanvasDisplay(false)
    setCanvasEventsEnable(true) //
    setTicking(false)
    setDisabledRouteButton(true)//
    setDisabledRouteLoadButton(true)
    setDisabledRouteDownloadButton(true)
    
    setVideoData(null);
    setStoredAreas([])
    setAreaExists(false)
    setDisabledVideoController(false)
    setVideoWidth(400)
    setVideoHeight(300)
    setStoredPath([])
    
    setAreaGroupID(null)
    setDisabledReportsButton(true)//
    setOpenReportsDialog(false)
    setCanvasZIndex('999');//
    setCanvasDrawAnnotate(false);//
    setAnnotateFileName(null);//
    setVideoTime(0);
    setDialogTitle('New Project');
    setRouteLoading(false)
    setAlertUploadOpen(false)
    setAnnotationIsDone(false)
    setOpenEmailDialog(false)
    setProjectNameAsFileNameDisabled(false)
    setProjectNameAsFileName(false)
    
  }
  // useEffect(() => {
  //   console.log("projectNameAsFileName: ", projectNameAsFileName)
  //   setProjectNameDisabled(projectNameAsFileName)
  //   //setDisabledUpload()
  // }, [
  //     projectNameAsFileName
  // ]);
  
  const reload_project = () =>{
    console.log("in reload_project")
    if(props.projectData.id===null){
      handleInit()
      return
    }
    setProjectNameDisabled(props.projectData.name!='')
    setProjectNameAsFileNameDisabled(props.projectData.name!='')
    setDisabledRouteDownloadButton(true)
    
    if(props.projectData.name!=''){
      setDialogTitle(props.projectData.name)
    }
    if(props.projectData.id!=null && props.projectData.url!=null){
      setDisabledUpload(true)
    }else{
      setDisabledUpload(props.projectData.id!=null && !(props.projectData.url!=null && props.projectData.url!=undefined))  
    }
    
    setProjectName(props.projectData.name)
    setVideoSource(props.projectData.url)
    if(props.projectData.id!=null && props.projectData.url!=null){
      setLoading(true)
      getVideoForProject(props.projectData.id).then(v=>{
        setVideoData(v[0])
        setDisabledVideoController(true)
        setCanvasDisplay(true)
        setVideoWidth(400)
        if (videoref.current!=null && videoref.current.videoWidth>0){
          setVideoHeight(videoref.current.videoHeight*400/videoref.current.videoWidth)
        }
        if(v!=null && v.length>0){
          loadAreaGroupByProject(props.projectData.id).then(ag =>{
            if(ag.length>0){
              setDisabledRouteLoadButton(true)
              setCanvasZIndex(null)
              console.log("props.projectData: ", props.projectData)
              if(videoref.current!=null)
                displayAreas(props.projectData.id, 400, videoref.current.videoHeight*400/videoref.current.videoWidth)
              setCanvasDrawAnnotate(true)
              setLoading(false)
              setAreaExists(true)
              annotationStatus(v[0].id).then( st =>{
                if(st.status==='NA' && props.projectData.url!=null){
                  setDisabledAnnotate(false)
                  setLoading(false)
                  
                }else if(st.status==='running'){
                  setDisabledAnnotate(true)
                  setAnnotateLoading(true)
                  setTicking(true)
                  setLoading(false)
                }else if(st.status==='done'){
                  setAnnotationIsDone(true)
                  setDisabledAnnotate(true)
                  setAnnotateLoading(false)
                  setDisabledVideoController(false)
                  setTicking(false)
                  
                  getAnnotationForVideo(v[0].id).then( an =>{
                    if(an!=null && an.length>0){
                       setDisabledReportsButton(false)
                       setLoading(false)
                      
                    }else{
                      setLoading(false)
                    }
                    
                  })
                  
                }else if(st==='failed'){
                  // annotation failed
                  console.log("in annotation failed")
                  setDisabledAnnotate(true)
                  setAnnotateLoading(false)
                  setAnnotationFailed(true)
                  setTicking(false)
                }  
              }).catch(e=>console.log("error in annotationStatus: ", e))
              
            }else{
              setDisabledRouteLoadButton(false)
              setLoading(false)
            }
          })
          
          
        }else{
          setLoading(false)
        }
        
      })
      
    }
    
    const timer = setInterval(() => getAnnotation(props.projectData.id), 10000);
    return () => clearInterval(timer)
  }
  
  useEffect(() => {
    reload_project()
    
  }, [
      ticking,
      props.projectData, 
    ]);
    
  
  
  const loadAreaGroupByProject = (project_id) =>{
    return new Promise((resolve, reject) => {
      if(project_id==null){
        resolve(null)
        return
      }
      console.log("project_id: ", project_id)
      HTTPrequest('areagroup', 'GET', {}, '?project='+project_id).then(g => {
            console.log("g0: ", g)
            resolve(g.results);
          }).catch(function (err){
            reject({
                  error: err
                });
          })
    })
  } 
      
  
  
  const annotationStatus = (video_id) => {
    return new Promise((resolve, reject) => {
    getAnnotationForVideo(video_id).then(an => {
        if(an!=null && an.length>0){
          setAnnotateFileName(an[0].file_name)
          resolve(
            {
              status:an[0].status,
              id:an[0].id
            }
          )
        }else resolve({
          status:'NA',
          id:null
          
        }
        ) 
      }).catch(e => {
        reject(e) 
      })
    })
  }
    
  
  const handleAnnotate = async () => {
    setOpenEmailDialog(true)
  }
  
  const loadProcess = (project_id) => {
    return new Promise((resolve, reject) => {
      loadAreaGroupByProject(project_id).then(g => {
        if(g===null){
            resolve(null)
        }
        if (g.length>0){
          HTTPrequest('process', 'GET', {}, '?area_group='+g[0].id).then(p => {
            resolve(p.results);
          }).catch(function (err){
            console.log("error in loadProcess", err)
            reject({
                  error: err
                });
          })
        }
      })
    })
  }
  
  const loadReport = (process_id) => {
    return new Promise((resolve, reject) => {
      HTTPrequest('report', 'GET', {},'?process='+process_id+'&page_size=10000').then(report => {
        resolve(report.results)
      })
      
    })
  }
      
  const loadAreas = (project_id) => {
    return new Promise((resolve, reject) => {
      loadAreaGroupByProject(project_id).then(g => {
        
        if(g===null){
          resolve(null)
          return
        }
        if (g.length>0){
          setAreaGroupID(g[0].id)
          console.log("area group: ", g[0].id)
          HTTPrequest('area', 'GET', {}, '?group='+g[0].id).then(ar => {
            resolve(ar);
          }).catch(function (err){
            reject({
                  error: err
                });
          })
        }else{
          resolve(null);
        }
      }).catch(e=>console.log("error in loadAreaGroup: ", e))
    })
  }
      
  const saveAreas = () => {
    return new Promise((resolve, reject) => {
      console.log("storedAreas ", storedAreas)
      createAreaGroup(props.projectData.id).then(response => {
        
        setAreaGroupID(response.data.id)
        storedAreas.map( s=>{
          createArea(response.data.id, s.area_type, s.area_name).then(res =>{
            var saved_coord = []
            s.area_lines.map( (l, i) => {
              addCoordinate(l.x1, l.y1, res.data.id, i).then(c =>{
                saved_coord.push(i)
                if(saved_coord.length===s.area_lines.length){
                    resolve()
                  }
              }).catch(function (err) {
                console.log("addCoordinate error: ", err)
                reject()
              })
              
            })
            
          }).catch(function (err) {
            console.log("createArea error: ", err)
            reject()
          })
        })
            
      }).catch(function (err) {
        console.log("createAreaGroup error: ", err)
        if (err.status === 401) navigate('/login');
        reject()
      });
    })
  }
  
  const displayAreas = (project_id, video_width, video_height) => {
    
    loadAreas(project_id).then( ar => {
      if(ar===null)
        return
      var stored_path = []
      ar.results.map(a => {
        var stored_area = []
        HTTPrequest('coordinates', 'GET', {}, '?area='+a.id).then(c => {
          let sorted_coordinates = c.results.sort((a,b) => a.order - b.order);
          for(var j=1;j<sorted_coordinates.length;j++){
            stored_area.push({x1:sorted_coordinates[j-1].x*video_width, y1:sorted_coordinates[j-1].y*video_height, x2:sorted_coordinates[j].x*video_width, y2:sorted_coordinates[j].y*video_height})
          }
          stored_area.push({x1:sorted_coordinates[sorted_coordinates.length-1].x*video_width, y1:sorted_coordinates[sorted_coordinates.length-1].y*video_height, x2:sorted_coordinates[0].x*video_width, y2:sorted_coordinates[0].y*video_height})
          stored_path.push(stored_area)
          setStoredPath(stored_path)
          if(stored_path.length>0)
            setCanvasDisplay(true)
            setCanvasEventsEnable(false)
        })
      })
    })
  }
  
  
  const createAreaGroup = (project_id) => {
    return HTTPrequest('areagroup', 'POST', {
          project: project_id
        }) 
  }
  
  const createArea = (areagroup_id, area_type, area_name) => {
    return HTTPrequest('area', 'POST', {
      group: areagroup_id,
      area_type: area_type,
      name: area_name
    })
  }
  
  const addCoordinate = (x, y, area_id, ind) =>{
    return HTTPrequest('coordinates', 'POST', {
      x: x/videoWidth,
      y: y/videoHeight,
      area: area_id,
      order: ind
    })
    
  }
  
  const getAnnotation = (project_id) => {
    if (ticking) {
      getAnnotationForProject(project_id).then(response => {
        if (response.length == 0) {
          console.log("getAnnotation error 0 found")
        }
        else{
          setAnnotateFileName(response[0].file_name)
          if (response[0].status == "running"){
            // do nothing
          }
          else if (response[0].status == "done"){
            setAnnotationIsDone(true)
            setAnnotateLoading(false)
            
            setDisabledReportsButton(false)
            setDisabledVideoController(false)
            setTicking(false)
            
          }
          else if (response[0].status == "failed"){
            console.log("annotation failed")
            setAnnotateLoading(false)
            setAnnotationFailed(true)
            setTicking(false)
          }
        }
      }).catch(e => {
            console.log("getAnnotation error: ", e)
      });
    }
  }
  
  function handleClose() {
    if(videoLoading===true){
      setAlertUploadOpen(true)
    }else{
      
      handleInit()
      props.setProjectDialogOpen(false);
      
    }
      
      
      
    }
    

  return (
    
    <Dialog open={props.projectDialogOpen} onClose={handleClose} className={classes.main}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent >
          <div className={classes.videoContainer}>
            {
            (canvasDisplay)?
            (
            <Canvas
            stored_areas={storedAreas}
            set_stored_areas={setStoredAreas}
            setAreaExists={setAreaExists}
            video_width={videoWidth}
            video_height={videoHeight}
            stored_path={storedPath}
            canvasEventsEnable={canvasEventsEnable}
            canvasZIndex={canvasZIndex}
            canvasDrawAnnotate={canvasDrawAnnotate}
            annotateFileName={annotateFileName}
            project_id={props.projectData.id}
            videoTime={videoTime}
            setDisabledRouteButton={setDisabledRouteButton}
            annotationIsDone={annotationIsDone}
            
            />)
            :
            <></>
            }
            <video 
              ref={videoref}
              className = {classes.video}
              controls={(disabledVideoController)?null:"controls"}
              src={(videoSource)}
              onResize={(e) => {
                setVideoWidth(400);
                setVideoHeight(e.target.videoHeight*400/e.target.videoWidth);
                if(props.projectData.id!=null)
                  displayAreas(props.projectData.id, 400, e.target.videoHeight*400/e.target.videoWidth)
              }}
              onTimeUpdate={(e)=>{setVideoTime(e.target.currentTime)}}
            />
            
            
            <ExcelReportsDialog
              open={openReportsDialog}
              area_group={areaGroupID}
              setOpenReportsDialog={setOpenReportsDialog}
              projectName={projectName}
            />
              
            
          </div>
            
          <TextField
            disabled={projectNameDisabled || projectNameAsFileName}
            margin="dense"
            id="name"
            label="Project Name"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
          
          <br/>
          <FormControlLabel control={
            <Checkbox 
            disabled={projectNameAsFileNameDisabled}
            onChange={(e)=>(setProjectNameAsFileName(e.target.checked))}
            />
          } label="Use File Name" />
          <br/>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Files
              accept={["video/mp4"]}
              maxSize="500mb"
              onError={errors => {
                console.log("error in files")
                errors.map(error => {
                    console.log("error: ", error.type)
                  })
              }}
              onSuccess={async ([selectedFile]) => {
                var project_name = selectedFile.name
                if (projectNameAsFileName) {
                  setProjectName(selectedFile.name)
                }else{
                  project_name = projectName;
                }
                setVideoLoading(true);
                await postProject(project_name).then(function (project_response) {
                  props.setProjectData(project_response.data)
                  getPresignedPostData(selectedFile, project_response.data.id).then(function (presigned_response) {
                    const { file } = selectedFile.src;
                    uploadFileToS3(presigned_response, file).then(t => {
                      postVideo(project_name, project_response.data.id, selectedFile.name).then(function (video_response) {
                        setVideoLoading(false)
                        setVideoData(video_response.data)
                        setDisabledUpload(true)
                        updateVideoSource(selectedFile, project_response.data.id).then(function (presigned_get) {
                          setVideoSource(presigned_get.data.url);
                          setDisabledVideoController(true)
                          setCanvasDisplay(true)
                          setDisabledRouteLoadButton(false)
                        }).catch(function (err) {
                          console.log("updateVideoSource error")
                          if (err.status === 401) navigate('/login');
                        });
                      }).catch(function (err) {
                        console.log("postVideo error")
                        if (err.status === 401) navigate('/login');
                      });
                    }).catch(function () {
                      console.log("uploadFileToS3 error")
                    });
                  }).catch(function () {
                    console.log("getPresignedPostData error")
                  })
                
                }).catch(function (err) {
                  if (err.status === 401) navigate('/login');
                })
                
              }}
            >
            {({ browseFiles }) => <LoadingButton 
                                    variant="contained" 
                                    color="secondary" 
                                    size="small" 
                                    disabled={(projectName.length==0 && !projectNameAsFileName) || disabledUpload}
                                    onClick={browseFiles}
                                    loading={videoLoading}
                                    >Upload Video</LoadingButton>}
          </Files>
          
          <Files
              accept={["application/json"]}
              maxSize="1mb"
              onError={errors => {
                console.log("error in upload route")
                errors.map(error => {
                    console.log("error: ", error.type)
                  })
              }}
              onSuccess={async ([selectedFile]) => {
                
                const fileReader = new FileReader();
                fileReader.readAsText(selectedFile.src.file, "UTF-8");
                fileReader.onload = e => {
                  setStoredAreas(JSON.parse(e.target.result))
                  setRunDisplayAreas(true)
                  setDisabledRouteButton(false)
                };
                
                
              }}
            >
            {({ browseFiles }) => <LoadingButton 
                                    variant="contained" 
                                    color="secondary" 
                                    size="small" 
                                    disabled={!disabledRouteButton || disabledRouteLoadButton}
                                    onClick={browseFiles}
                                    
                                    >Upload Areas</LoadingButton>}
          </Files>
          
          <LoadingButton 
            variant="contained" 
            disabled={disabledRouteButton}
            loading={routeLoading}
            onClick={ () =>{
              setRouteLoading(true)
              saveAreas().then(()=>{
                  setRouteLoading(false)
                  setDisabledRouteLoadButton(true)
                  setDisabledRouteButton(true)
                  setCanvasEventsEnable(false)
                  setCanvasZIndex(null)
                  setCanvasDrawAnnotate(true)
                  setDisabledAnnotate(false)
                  setDisabledRouteDownloadButton(false)
                  // TODO: following doesn't work properly
                  // so when we load the areas from file it doesn't show the areas
                  
                  // if (runDisplayAreas) {
                  //   displayAreas(props.projectData.id, 400, videoref.current.videoHeight*400/videoref.current.videoWidth);
                  //   reload_project();
                  // }
                  
                })
              }}
            color="primary"
          >
            Set Areas
          </LoadingButton>
          
          <LoadingButton 
            variant="contained" 
            disabled={disabledRouteDownloadButton}
            onClick={ () =>{
              const fileName = projectName;
              const json = JSON.stringify(storedAreas, null, 2);
              const blob = new Blob([json], { type: "application/json" });
              const href = URL.createObjectURL(blob);
            
              // create "a" HTLM element with href to file
              const link = document.createElement("a");
              link.href = href;
              link.download = fileName + ".json";
              document.body.appendChild(link);
              link.click();
            
              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }}
            color="primary"
          >
            Download Areas
          </LoadingButton>
          
          
          <LoadingButton 
          disabled = {disabledAnnotate}
          variant="contained" 
          loading={annotateLoading}
          onClick={() => {
            handleAnnotate()
            }
            
          }
          >
            Annotate
          </LoadingButton>
          

          <Button 
            variant="contained" 
            disabled={disabledReportsButton}
            onClick={ () =>{
              setOpenReportsDialog(true)
            }} 
            color="secondary"
          >
            Reports
          </Button>
          
        </DialogActions>
        {
          (canvasDisplay && !areaExists)
          ?<Alert severity="error">You need to draw at least one Area or upload Area file</Alert>
          : <></>
        }
        {
          (annotationFailed)
          ?<Alert severity="error">Annotation Failed</Alert>
          :<></>
        }
        {
          (loading)?
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>:<></>
        }
        
        <EmailDialogSimple
          openEmailDialog={openEmailDialog}
          setOpenEmailDialog={setOpenEmailDialog}
          setDisabledRouteButton={setDisabledRouteButton}
          setDisabledAnnotate={setDisabledAnnotate}
          setAnnotateLoading={setAnnotateLoading}
          setTicking={setTicking}
          videoData={videoData}
        >
        </EmailDialogSimple>
        <Dialog
        open={alertUploadOpen}
        >
        <DialogTitle>Please wait until the upload is complete</DialogTitle>
        <DialogActions>
        <Button 
            variant="contained" 
            onClick={ () =>{
              setAlertUploadOpen(false)
            }} 
            color="secondary"
          >
            OK
          </Button>
        </DialogActions>
        
        </Dialog>
        
      </Dialog>
      
  );
}


export default ProjectDialog;

