import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles  } from '@material-ui/core/styles';
import MaterialReactTable from 'material-react-table';
import HTTPrequest from '../../helpers/HTTPrequest';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import TextField from '@material-ui/core/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({

  main:{
    // fullWidth: true,
    // maxWidth: 'lg'
    // fullScreen: true
  }

}));
 const getProjects = () => {
    var param = ''
    param='?page_size=1000&_video=false&_annotations=true'
    return HTTPrequest('project', 'GET', {}, param)
    
  };

let default_interval = "15"


const ReportGeneratorDialog = (props) => {
    const classes = useStyles();

  
  const [data, setData] = useState([]);
  // const [dateTime, setDateTime] = useState(dayjs().minute(0));
  const [intervals, setIntervals] = useState(["1", "5","10","15","20","30","60"]);
  const [interval, setInterval] = useState(default_interval);
  const [loadDonwload, setLoadDonwload] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
 

    
  const handleInit = () => {
    getProjects().then(t => {
      console.log("t.results: ", t.results)
      setData(t.results.filter(r => (r.videos.length>0 && r.videos[0].annotations!=null && r.videos[0].annotations.length>0)).map(x=>({
        id:x.id,
        name: x.name,
        created_at: Intl.DateTimeFormat('en-us', {
                              weekday: 'long',
                              month: 'long',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                              timeZone: 'UTC'
                            }).format(Date.parse(x.created_at)),
        start_at: dayjs().minute(0)
      })))
    }).catch(function () {
      console.log("getProjects error");
    });
  }
  
  
  useEffect(() => {
    if(props.reportGeneratorOpen) handleInit()
  }, [
    props.reportGeneratorOpen
  ]);
  
  

  const handleChange = (newValue, index) => {
    // setDateTime(newValue);
    let items = [...data];
    let item = {...data[index]};
    item.start_at = newValue;
    items[index] = item;
    setData(items);
    
  };
  
  const handleIntervalChange = (e) => {
    setInterval(e.target.value)
  }
  
  const columns =  [
      {
        accessorKey: 'name',
        header: 'Job Name',
      },
      {
        accessorKey: 'created_at',
        header: 'Created At',
      },
      {
        id: 'start_at', //id used to define `group` column
        header: 'Start At',
        Cell: ({ renderedCellValue, row }) => (

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                          
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Start of the video"
                    value={data[row.index].start_at}
                    onChange={(e)=>handleChange(e, row.index)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            ),
      }
    ]
    

  return (
    
    <Dialog 
      open={props.reportGeneratorOpen} 
      onClose={()=>{}} 
      className={classes.main}
      fullWidth={true}
      maxWidth='md'
      >
        <DialogTitle>Report Generator</DialogTitle>
        <DialogContent>
            <Box sx={{ my: 3, mx: 2 }}>

            <Typography variant="h7" color="text.secondary">
              Please select and sort the jobs you need to generate reports from in ascending time order
            </Typography>
          </Box>
          <Divider variant="middle" />
              <MaterialReactTable
                
                autoResetPageIndex={false}
                columns={columns}
                data={data}
                enableRowOrdering
                enableSorting={false}
                enableRowSelection
                // muiTableBodyRowProps={({ row }) => ({
                //   onClick: row.getToggleSelectedHandler(),
                //   sx: { cursor: 'pointer' },
                // })}
                onRowSelectionChange={setRowSelection} 
                state={{ rowSelection }}
                muiTableBodyRowDragHandleProps={({ table }) => ({
                  onDragEnd: () => {
                    const { draggingRow, hoveredRow } = table.getState();
                    
                    let selected_ids = data.map(a=>a.id).filter((a, ind)=>(ind in rowSelection))
                    console.log("selected_ids: ", selected_ids)
                    
                    if (hoveredRow && draggingRow) {
                      data.splice(
                        hoveredRow.index,
                        0,
                        data.splice(draggingRow.index, 1)[0],
                      );
                      let new_inds = data.map((a,ind) => ind).filter((ind)=>(selected_ids.includes(data[ind].id)))
                      setData([...data]);
                      
                      var dict = {}
                      new_inds.forEach(v => dict[v] = true)
                      setRowSelection(dict)
                    }
                  },
                })}
              />
        </DialogContent>
        <br/>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <TextField
            id="outlined-select-currency"
            select
            label="Time interval"
            defaultValue={default_interval}
            helperText="Report's time interval (in minutes)"
            onChange={handleIntervalChange}
          >
            {intervals.map((option) => (
              <MenuItem key={option} value={option} >
                {option}
              </MenuItem>
            ))}
          </TextField>
          
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <br/>
          <LoadingButton 
              loading={loadDonwload}
              // disabled={reportDisabled}
              onClick={()=>{
                let projects = data.filter((currElement, index) => rowSelection[index]).map(p => ({'id':p.id, 'start_at':p.start_at.format('YYYY-MM-DD HH:mm')+':00'}))
                setLoadDonwload(true)
                HTTPrequest('excelreport', 'POST', {
                    "projects": projects,
                    // "start_time": dateTime.format('YYYY-MM-DD HH:mm')+':00',
                    "interval": parseInt(interval)
                    
                  }).then(v => {
                    // window.open(v.url, '_blank', 'noreferrer');
                    fetch(v.url).then(response => {
                      response.blob().then(blob => {
                          // Creating new object of PDF file
                          const fileURL = window.URL.createObjectURL(blob);
                          // Setting various property values
                          let alink = document.createElement('a');
                          alink.href = fileURL;
                          alink.download =props.projectName+'.xlsx';
                          alink.click();
                          setLoadDonwload(false)
                      })
                    })
                  })
              }} 
              variant="contained"
              color="secondary" >
              Generate Report
            </LoadingButton>
          </Grid>
        <DialogActions>
          
          
          <Button onClick={() => {
              props.setReportGeneratorOpen(false)
            }} 
            color="primary">
            Close
          </Button>
          
        </DialogActions>
        
      </Dialog>
      
  );
}


export default ReportGeneratorDialog;

