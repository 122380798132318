import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { red} from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Grid from '@mui/material/Grid';
import HTTPrequest from '../../helpers/HTTPrequest';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: red[500],
  },
});

const ProjectCard = (props) => {
  const classes = useStyles();
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
    
  const deleteProject = () => {
    return HTTPrequest('project/' + props.project_id, 'DELETE', {})
  }

  return (
    <Box p={3}>
    <Dialog
        open={openDeleteWarning}
        onClose={()=>{}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
      Are you sure you want to delete {props.name} project?
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setOpenDeleteWarning(false)
        }} color="primary">
          Cancel
        </Button>
        <Button onClick={ () =>{
            deleteProject().then(e => {
              props.handleUpdate()
              setOpenDeleteWarning(false)
            })
          }
        } 
        color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    {
    (props.name!=undefined)?
        <Card>
          <CardContent>
            <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    {(props.name!=undefined)?props.name.charAt(0):'?'}
                  </Avatar>
                }
                
                title={`${props.name}`}
                subheader={
                (props.created_at!=undefined)?
                `${new Intl.DateTimeFormat('en-us', {
                              weekday: 'long',
                              month: 'long',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                              timeZone: 'UTC'
                            }).format(Date.parse(props.created_at))}`
                    :
                    '?'
                }
              />
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <video 
                width="320"
                src={props.video_url}
                >
                </video>
                <br/>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Button 
                  onClick={()=>{
                    props.setProjectData({
                      name: props.name,
                      created_at: props.created_at,
                      url: props.video_url,
                      id: props.project_id
                    })
                    props.setProjectDialogOpen(true)
                    
                  }} 
                  variant="contained"
                  color="secondary" >
                    Open
                  </Button>
                  <Button 
                  onClick={()=>{
                    setOpenDeleteWarning(true)
                    
                  }} 
                  variant="contained"
                  color="secondary" >
                    Delete
                  </Button>
                </Grid>
              </Grid>
          </CardContent>
        </Card>
      :<></>
    }
    </Box>
  );
}




export default ProjectCard;