var sysApi = {
        host: 'https://9a7f-34-234-54-130.ngrok-free.app',
        basic_plan_id: '',
        stripe_key: '',
    }
if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION"){
    sysApi = {
        host: 'https://api.roadsmonitoring.com',
        basic_plan_id: '',
        stripe_key: '',
    }
}    



export default sysApi;