import sysApi from './config';

const HTTPrequest = (uri, type, data, param='') => {
  return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      // Set the proper URL here.
      const url = `${sysApi.host}/${uri}/${param}`;
      xhr.open(type, url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", `Token ${localStorage.getItem('token')}`);
      
      xhr.send(
        JSON.stringify(data)
      );
      xhr.onload = function() {
        
        if (this.status == 200) {
          resolve(JSON.parse(this.responseText));
        } else {
          console.log("error in HTTPrequest: ", xhr.responseText)
          console.log("uri: ",uri)
          console.log("data: ",data)
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
    });
  
}

export default HTTPrequest;