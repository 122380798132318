
import HTTPrequest from './HTTPrequest';

export const postProject = (project_name) => {
  return HTTPrequest('project', 'POST', {name: project_name})
}

export const getPresignedPostData = (selectedFile, project_id) => {
  return HTTPrequest('signedurl', 'POST', {
        file_name: selectedFile.name,
        type: 'POST',
        file_type: 'video',
        project: project_id
      })
};

export const uploadFileToS3 = (presigned_data, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    
    
    formData.append("file", file);
    formData.append('fileName', file.name);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", presigned_data.data.url, true);
    xhr.send(formData);
    xhr.onload = function() {
      if (this.status == 204 || this.status == 200) {
        resolve();
      } else {
        console.log("error in uploadFileToS3: ", this.status)
        console.log("statusText: ", xhr.statusText)
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
  });
};


export const postVideo = (name, project_id, file_name) => {
  return HTTPrequest('video', 'POST', {
          file_name: file_name,
          project: project_id,
          name: name
        })
  }
  
export const updateVideoSource= (selectedFile, project_id) => {
 return HTTPrequest('signedurl', 'POST', {
        file_name: selectedFile.name,
        type: 'GET',
        file_type: 'video',
        project: project_id
      }) 
}