import ReactDOM from 'react-dom';
import React, {Component, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';



function AreaDialog(props) {
  const [areaName, setAreaName] = useState("")
  const [areaType, setAreaType] = React.useState('north');

  const handleAreaTypeChange = (event) => {
    setAreaType((event.target).value);
  };
  const saveChange = () => {
    let stored_path = [...props.stored_path, props.stored_lines]
    props.set_stored_path(stored_path)
    
    let new_area={
        area_lines: props.stored_lines,
        area_name: areaName,
        area_type: areaType
    }
    let tmp_stored_areas = [...props.stored_areas, new_area]
    props.set_stored_areas(tmp_stored_areas)
    
    props.setAreaExists(true)
    props.setDisabledRouteButton(false)
    
    // if(areaType === 'enter'){
    //     props.enter_exists_set_state(true)
    //     if(props.exitExists){
    //       props.setDisabledRouteButton(false)
    //     }
    // }else{
    //     props.exit_exists_set_state(true)
    //     if(props.enterExists){
    //       props.setDisabledRouteButton(false)
    //     }
    // }
    
    
    props.setStarted(false)
    props.set_in_progress_area([])
    props.set_stored_lines([])
    
    
  }
  
  const cancleChange = () => {
    props.setStarted(false)
    props.set_in_progress_area([])
    props.set_stored_lines([])
  }
  
  
  
  function handleClose() {
    
    props.setOpen(false)
  }
  
  
    return (
  <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Addine New Area</DialogTitle>
        <DialogContent>
        
        <Grid
            container
            direction="column"
            justifyContent="space-between"
        >
        
            
        
            <br/>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Area Type</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={areaType}
                onChange={handleAreaTypeChange}
              >
                <FormControlLabel value="north" control={<Radio />} label="North bound" />
                <FormControlLabel value="south" control={<Radio />} label="South bound" />
                <FormControlLabel value="east" control={<Radio />} label="East bound" />
                <FormControlLabel value="west" control={<Radio />} label="West bound" />
                <FormControlLabel value="other" control={<Radio />} label="Other"/>
              </RadioGroup>
            </FormControl>
            <TextField
                disabled= {!(areaType==="other")}
                label="Area Name"
                onChange={(e) => setAreaName(e.target.value)}
            />
        </Grid>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            // remove unsaved area from canvas
            cancleChange()
            handleClose()
          }} color="primary">
            Cancel
          </Button>
          <Button onClick={ () =>{
            saveChange()
            handleClose()
          }
          } 
          color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  
}

export default AreaDialog;