import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles  } from '@material-ui/core/styles';
import HTTPrequest from '../../helpers/HTTPrequest';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@material-ui/core/Button';
import MaterialReactTable from 'material-react-table';
import Files from "react-butterfiles";
import {postProject,getPresignedPostData, uploadFileToS3, postVideo, updateVideoSource} from '../../helpers/tools';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({

  main:{
  }

}));


const BulkProjectsDialog = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  // const [loadDonwload, setLoadDonwload] = useState(false);
  const [data, setData] = useState([
    {
      'project_name':''
    }
    ]);
  const [metaData, setMetaData] = useState([
    {
      'video_loading':false,
      'disable_upload_video': false,
      'disable_upload_route': true
    }
  ]);
  
  const handleMetaDataChange = (newValue, index, key) => {
    // setDateTime(newValue);
    let items = [...metaData];
    let item = {...metaData[index]};
    item[key] = newValue;
    items[index] = item;
    setMetaData(items);
    
  };
  
  
 const columns =  [
      {
        id: 'upload_video', 
        header: 'Upload Video',
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Files
              accept={["video/mp4"]}
              maxSize="500mb"
              onError={errors => {
                console.log("error in files")
                errors.map(error => {
                    console.log("error: ", error.type)
                  })
              }}
              onSuccess={async ([selectedFile]) => {
                var project_name = selectedFile.name
                // if (projectNameAsFileName) {
                //   setProjectName(selectedFile.name)
                // }else{
                //   project_name = projectName;
                // }
                // setVideoLoading(true);
                handleMetaDataChange(true, row.index, 'video_loading')
                
                await postProject(project_name).then(function (project_response) {
                  // props.setProjectData(project_response.data)
                  getPresignedPostData(selectedFile, project_response.data.id).then(function (presigned_response) {
                    const { file } = selectedFile.src;
                    uploadFileToS3(presigned_response, file).then(t => {
                      postVideo(project_name, project_response.data.id, selectedFile.name).then(function (video_response) {
                      handleMetaDataChange(false, row.index, 'video_loading')
                      //   setVideoData(video_response.data)
                      
                        updateVideoSource(selectedFile, project_response.data.id).then(function (presigned_get) {
                          // setVideoSource(presigned_get.data.url);
                          // setDisabledVideoController(true)
                          // setCanvasDisplay(true)
                          // setDisabledRouteLoadButton(false)
                          handleMetaDataChange(true, row.index, 'disable_upload_video')
                          handleMetaDataChange(false, row.index, 'disable_upload_route')
                          
                        }).catch(function (err) {
                          console.log("updateVideoSource error")
                          if (err.status === 401) navigate('/login');
                        });
                      }).catch(function (err) {
                        console.log("postVideo error")
                        if (err.status === 401) navigate('/login');
                      });
                    }).catch(function () {
                      console.log("uploadFileToS3 error")
                    });
                  }).catch(function () {
                    console.log("getPresignedPostData error")
                  })
                
                }).catch(function (err) {
                  if (err.status === 401) navigate('/login');
                })
                
              }}
            >
              {({ browseFiles }) => <LoadingButton 
                                      variant="contained" 
                                      color="secondary" 
                                      size="small" 
                                      disabled={metaData[row.index]['disable_upload_video']}
                                      onClick={browseFiles}
                                      loading={metaData[row.index]['video_loading']}
                                      >Upload</LoadingButton>}
            </Files>
          </Box>
        ),
      },
      {
        accessorKey: 'name',
        header: 'Project Name',
      },
      {
        id: 'upload_areas', 
        header: 'Upload Areas',
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
          <Files
              accept={["application/json"]}
              maxSize="1mb"
              onError={errors => {
                console.log("error in upload route")
                errors.map(error => {
                    console.log("error: ", error.type)
                  })
              }}
              onSuccess={async ([selectedFile]) => {
                
                const fileReader = new FileReader();
                fileReader.readAsText(selectedFile.src.file, "UTF-8");
                fileReader.onload = e => {
                  // setStoredAreas(JSON.parse(e.target.result))
                  // setRunDisplayAreas(true)
                  // setDisabledRouteButton(false)
                };
                
                
              }}
            >
            {({ browseFiles }) => <LoadingButton 
                                    variant="contained" 
                                    // color="secondary" 
                                    size="small" 
                                    disabled={metaData[row.index]['disable_upload_route']}
                                    onClick={browseFiles}
                                    
                                    >Upload</LoadingButton>}
          </Files>
            
          </Box>
        ),
      },
      {
        id: 'annotate', 
        header: 'Annotate',
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <LoadingButton 
              variant="contained" 
              color="secondary" 
              size="small" 
              disabled={true}
              // disabled={(projectName.length==0 && !projectNameAsFileName) || disabledUpload}
              // onClick={browseFiles}
              // loading={videoLoading}
              >Annotate</LoadingButton>
          </Box>
        ),
      }
    ]

    
  const handleInit = () => {
    
  }
  
  
  useEffect(() => {
    if(props.bulkProjectsOpen) handleInit()
  }, [
    props.bulkProjectsOpen
  ]);
  

    

  return (
    
    <Dialog 
      open={props.bulkProjectsOpen} 
      onClose={()=>{}} 
      className={classes.main}
      fullWidth={true}
      maxWidth='md'
      >
        <DialogTitle>Add Projects in Bulk</DialogTitle>
        <DialogContent>
            <Box sx={{ my: 3, mx: 2 }}>
            <Typography variant="h7" color="text.secondary">
              Some text
            </Typography>
          </Box>
          <Divider variant="middle" />
          
          <MaterialReactTable
                
                autoResetPageIndex={false}
                columns={columns}
                data={data}
                // enableRowOrdering
                enableSorting={false}
                enableRowSelection
                // muiTableBodyRowProps={({ row }) => ({
                //   onClick: row.getToggleSelectedHandler(),
                //   sx: { cursor: 'pointer' },
                // })}
                // onRowSelectionChange={setRowSelection} 
                // state={{ rowSelection }}
                
              />
        </DialogContent>
        <br/>

        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <br/>
          <LoadingButton 
              // loading={loadDonwload}
              onClick={()=>{
                // setLoadDonwload(true)
                HTTPrequest('excelreport', 'POST', {
                    // "projects": projects,
                  }).then(v => {
                    //
                  })
              }} 
              variant="contained"
              color="secondary" >
              Generate Report
            </LoadingButton>
          </Grid>
        <DialogActions>
          
          <Button onClick={() => {
              props.setBulkProjectsOpen(false)
            }} 
            color="primary">
            Close
          </Button>
          
          
        </DialogActions>
        
      </Dialog>
      
  );
}


export default BulkProjectsDialog;

